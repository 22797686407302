/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */

import axios from 'axios'
import router from '../router'
import { Message, Notification } from 'element-ui'

let cancelToken = axios.CancelToken

// 取消重复点击，保留最后一次
let pending = []
const translationUrl = (config) => {
  // 这里的标识是用请求地址+参数&请求方式拼接的字符串
  return (
    [
      config.method === 'get'
        ? config.url + encodeURIComponent(JSON.stringify(config.params))
        : config.url + encodeURIComponent(JSON.stringify(config.data))
    ] + config.method
  )
}
const removePending = (url) => {
  for (let p in pending) {
    if (pending[p].u === url) {
      // 当前请求在数组中存在时执行函数体
      pending[p].f('canceled') //执行取消操作
      pending.splice(p, 1) //把这条记录从数组中移除
    }
  }
}

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户没有授权，访问被禁止。',
  404: '请求资源不存在，服务器没有进行操作。',
  405: '请求方法未允许。',
  406: '请求的格式不正确。',
  408: '请求超时。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
  505: 'http版本不支持该请求。'
}

let axiosDemo = axios.create({
  baseURL: process.env.VUE_APP_URL_BASE,
  timeout: 8000,
  crossDomain: true,
  // withCredentials: true, // 前端跨域传递Cookie设置
  headers: {
    // common: {
    //   Authorization: AUTH_TOKEN ? 'Bearer ' + AUTH_TOKEN : ''
    // },
    get: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    // post: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
    // put: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }
  }
})

// 请求拦截器 可以设置token信息
axiosDemo.interceptors.request.use(
  (config) => {
    // 设置的请求头信息
    let AUTH_TOKEN = sessionStorage.getItem('TOKEN')
    let traceid = sessionStorage.getItem('traceId')
    if (AUTH_TOKEN) {
      config.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN
      if (traceid) config.headers.traceid = traceid
    }
    // 取消重复点击，保留最后一次
    let url = translationUrl(config)
    // 在一个ajax发送前执行一下取消操作
    removePending(url)
    config.cancelToken = new cancelToken((c) => {
      pending.push({ u: url, f: c })
    })
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
axiosDemo.interceptors.response.use(
  (response) => {
    const { config, data, status: statusCode } = response
    // 取消重复点击，保留最后一次，把已经完成的请求从pending中移除
    removePending(translationUrl(config))
    if (statusCode !== 200) return Promise.reject(data)
    // 返回原始数据
    const { origin, html = false, responseType } = config
    if (origin) return Promise.resolve(data)
    // 兼容下载
    if(responseType === 'blob') return Promise.resolve(data)
    // 自定义状态处理
    const { status, msg } = data
    if (status) {
      Message({ message: msg, type: 'error', dangerouslyUseHTMLString: html})
      if (status === 100) {
        router.push('/login')
      }
      return Promise.reject(data.data)
    }
    return Promise.resolve(data.data)
  },
  (error) => {
    const { response, request, message } = error
    // 判断请求异常信息中是否含有超时timeout字符串
    if (message?.includes('timeout')) {
      Message.error('请求超时！')
      return Promise.reject(error)
    }
    // 取消请求
    if (message === 'canceled') {
      return Promise.reject(error)
    }
    // HTTP Error 处理响应错误
    if (response) {
      const { status, data } = response
      if (status) {
        const msg = codeMessage[status] || data.msg || '请求失败'
        Message.error(msg)
        // 401: 未登录
        if (status === 401) {
          router.push('/login')
        // 403 token过期
        } else if (status === 403) {
          router.replace({path: '/login',query: { redirect: router.currentRoute.fullPath }})
        }
        return Promise.reject(response)
      } else {
        Message.error('连接到服务器失败!')
        return Promise.reject(response)
      }
    }
    // Request Error 请求发出但没有收到响应
    else if (request) {
      Message.error(navigator.onLine ? '网络请求失败！' : '网络连接错误！')
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default axiosDemo

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} config [其他配置的参数]
 */
export function get(url, params = {}, config = {}) {
  return new Promise((resolve, reject) => {
    const { params: newParams = {}, ...other } = config
    axiosDemo
      .get(url, { ...other, params: { ...newParams, ...params } })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 * @param {Object} config [其他配置的参数]
 */
export function post(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    const { data: newData = {}, ...other } = config
    axiosDemo
      .post(url, { ...newData, ...data }, other)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 * @param {Object} config [其他配置的参数]
 */
export function put(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    const { data: newData = {}, ...other } = config
    axiosDemo
      .put(url, { ...newData, ...data }, other)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} config [其他配置的参数]
 */
export function dele(url, params = {}, config = {}) {
  return new Promise((resolve, reject) => {
    const { params: newParams = {}, ...other } = config
    axiosDemo
      .delete(url, { ...other, params: { ...newParams, ...params } })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * upload方法，对应upload请求
 * @param {String} url [请求的url地址]
 * @param {Object} file [请求时携带的参数]
 * @param {Object} config [其他配置的参数]
 */

export function upload(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    const { callback, controller, show, ...other } = config
    const newConfig = {
      timeout: 1e2 * 60 * 1000,
      headers: { 'Content-Type': 'multipart/form-data' },
      ...other,
      onUploadProgress: progressEvent => {
        let progress = 0
        if (progressEvent.lengthComputable) {
          progress = (progressEvent.loaded / progressEvent.total) * 100 // | 0
          progress = Number(progress.toFixed(1))
        } else {
          progress = '上传中'
        }
        callback && callback({ progress, status: 'uploading' })
      }
    }
    if (controller) {
      newConfig.signal = controller.signal
    }
    show && Notification({
      type: 'info',
      title: '温馨提示',
      message: '如果数据庞大会导致上传缓慢哦，请您耐心等待！',
      offset: 100
    })
    axiosDemo
      .post(url + `?random=${Math.random()}`, data, newConfig)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * download方法，对应download请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} config [其他配置的参数]
 */
export function download(url, params = {}, config = {}) {
  return new Promise((resolve, reject) => {
    const { callback, controller, params: newParams = {}, httpType = 'get', name, type = 'csv', show, ...other } = config
    const newConfig = {
      timeout: 1e2 * 60 * 1000,
      params: { ...newParams, ...params },
      ...other,
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        let progress = 0
        if (progressEvent.lengthComputable) {
          progress = (progressEvent.loaded / progressEvent.total) * 100 // | 0
          progress = Number(progress.toFixed(1))
        } else {
          progress = '下载中'
        }
        const status = progress === 100 ? 'success' : 'downloading'
        callback && callback({ progress, status })
      }
    }
    if (controller) {
      newConfig.signal = controller.signal
    }
    show && Notification({
      type: 'info',
      title: '温馨提示',
      message: '如果数据庞大会导致下载缓慢哦，请您耐心等待！',
      offset: 100
    })
    axiosDemo[httpType](url, newConfig).then(res => {
      const fileReader = new FileReader()
      fileReader.onload = function() {
        try {
          // 说明时普通数据，后台失败
          let jsonData = JSON.parse(fileReader.result)
          const { status, msg } = jsonData
          if (status) {
            Message.error(msg)
            if (status === 100) {
              router.push('/login')
            }
          }
        } catch (error) {
          // 解析失败说明是正常文件流
          const filename = (name ? name : Date.now()) + '.' + type
          const d = document.createElement('a')
          const href = window.URL.createObjectURL(new Blob([res]))
          d.href = href
          d.download = decodeURIComponent(filename)
          document.body.appendChild(d)
          d.click()
          document.body.removeChild(d)
          window.URL.revokeObjectURL(href)
          callback && callback({ progress: 100, status: 'success' })
        }
        resolve(res)
      }
      fileReader.readAsText(res)
    })
    .catch(err => {
      callback && callback({ progress: 0, status: 'error' })
      reject(err)
    })
  })
}
