import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import "@/components/global"
import "@/utils/extra.js"
// import 'element-ui/lib/theme-chalk/index.css'
import utils from './utils/utils'
import './assets/css/theme/index.css'
import VueCropper from 'vue-cropper'
import myComponents from './components/index'
import Verify from './utils/verify'
import { message } from './components/rewriteComponents'
//富文本框 样式
import '@wangeditor/editor/dist/css/style.css'
//引入重置样式
import '@/assets/css/reset.scss'
// zhubo 2023_01_04 一对一辅导 早操公用样式
import '@/assets/scss/zb_style.scss'
import '@/assets/css/2023_01_04.scss'

//注册全局过滤器
import * as filters from '@/filters'
import tools from './utils/tools'
import {listDataControl} from "@/utils/plugins"
tools.listenPromiseCatch()
Vue.use(ElementUI)
tools.calcRem();
window.addEventListener("resize", ()=>{
  tools.calcRem();
});
Vue.use(VueCropper)
Vue.use(myComponents)
Vue.config.productionTip = false
Vue.use(utils)
// 列表数据控制( 根据prevOperation 状态控制页面的刷新与重置)
Vue.use(listDataControl)
Vue.prototype.$message = message
Vue.prototype.$OssAddress = process.env['VUE_APP_URL_OSS']

Object.keys(filters).forEach(v => {
  // 注册
  Vue.filter(v, filters[v])
})
// 禁用缩放
// tools.disableMousewheel();
// Vue.http.options.credentials = true
// 兼容360
// import 'babel-polyfill'
// Vue.http.options.credentials = true

Vue.prototype.$verify = Verify
Vue.config.silent =true;
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
