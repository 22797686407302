<template>
  <div class="t-table">
    <div>
      <el-table
          ref="table"
          v-loading='loading'
          :cell-style='cellStyle'
          :data='tableData'
          :stripe='false'
          size='small'
          style='width: 100%'
          @sort-change="sortChange"
          v-bind='property'
          v-on='events'
      >
        <template slot='empty'>
          <t-result type='empty' :text="empty_text"></t-result>
        </template>
        <template
            v-for='item in tableConfig'>
          <el-table-column
              v-if="!['index','expand','selection','number'].includes(item.type)"
              :label='item.label'
              :min-width='item.minWidth'
              :prop='item.prop'
              :show-overflow-tooltip='item.showOverflowTooltip==null?true:item.showOverflowTooltip'
              :type='item.type'
              :width='item.width'
              :fixed="item.fixed || (item.handle?'right':null)"
              :sortable="item.sortable || false"
              :align="item.align"
              v-bind='item.property||{}'>
            <template slot-scope='scope'>
              <div v-if='!!item.handle'>
                <template v-for='(handle, index) in handleBtn(item,scope.row)'>
                  <a class='t-handle' href='javascript:;' @click='onHandle(scope.row, handle.button, index,handle)'>{{handle.button}}</a>
                </template>
              </div>
              <div v-else-if="item.type === 'switch'">
                <el-switch
                    v-model="scope.row[item.prop]"
                    active-color="#1d2088"
                    @change="onSwitch(scope.row)"
                    inactive-color="rgba(0,0,0,.2)">
                </el-switch>
              </div>
              <div v-else-if="item.type === 'switch2'">
                <el-switch
                    v-model="scope.row[item.prop]"
                    :active-value=1
                    :inactive-value=0
                    active-color="#13ce66"
                    @change="onSwitch(scope.row)"
                >
                </el-switch>
              </div>
              <div
                  v-else
                  :class="item.showOverflowTooltip || item.showOverflowTooltip == null?'text-one':''"
                  :style="{textAlign:item.align?item.align:typeof scope.row[item.prop] === 'number'?'center':'left'}"
              >
                <!--       修改逻辑 : 之前的逻辑会将0一起给屏蔽掉 , 有的数据就是0, 需要展示(只屏蔽为null或者空字符串的无用数据)         -->
                <span v-if='!item.render'>{{ scope.row[item.prop] == null ||scope.row[item.prop] === ""? "-":scope.row[item.prop] }} </span>
                <span v-else v-html='item.render(scope.row)'></span>
              </div>
            </template>

            <!-- 合并单元格 -->
            <template
                v-for='_item in item.children'>
              <el-table-column
                  v-if="!['index','expand','selection','number'].includes(_item.type)"
                  :label='_item.label'
                  :min-width='_item.minWidth'
                  :prop='_item.prop'
                  :show-overflow-tooltip='_item.showOverflowTooltip==null?true:_item.showOverflowTooltip'
                  :type='_item.type'
                  :width='_item.width'
                  :sortable="item.sortable || false"
                  :fixed="_item.fixed"
                  v-bind='_item.property||{}'>
                <template slot-scope='scope'>
                  <div v-if='!!_item.handle'>
                    <template v-for='(handle, index) in handleBtn(item,scope.row)'>
                      <a class='t-handle' href='javascript:;' @click='onHandle(scope.row, handle.button, index,handle)'>{{handle.button}}</a>
                    </template>
                  </div>
                  <div v-else-if="_item.type === 'switch'">
                    <el-switch
                        v-model="scope.row[_item.prop]"
                        active-color="#1d2088"
                        @change="onSwitch(scope.row)"
                        inactive-color="rgba(0,0,0,.2)">
                    </el-switch>
                  </div>
                  <div v-else :class="_item.showOverflowTooltip || _item.showOverflowTooltip == null?'text-one':''">
                    <span v-if='!_item.render'>{{ scope.row[_item.prop] }} </span>
                    <span v-else v-html='_item.render(scope.row)'></span>
                  </div>
                </template>
              </el-table-column>
            </template>

          </el-table-column>
          <el-table-column v-else-if="item.type === 'number'" :label='item.label' :width='item.width'
                           align="center"
                           :fixed="item.fixed" v-bind='item.property||{}'>
            <template slot-scope='scope'>
              <div>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column v-else
                           :label='item.label'
                           :min-width='item.minWidth'
                           :prop='item.prop'
                           :fixed="item.fixed"
                           :show-overflow-tooltip='item.showOverflowTooltip==null?true:item.showOverflowTooltip'
                           :type='item.type'
                           :width='item.width'
                           :sortable="item.sortable || false"
                           v-bind='item.property||{}'>
          </el-table-column>
        </template>
        <slot name='column'></slot>
      </el-table>
    </div>
    <div class='t-pagination' v-if="hasPagination">
      <el-pagination
          v-if='total != null'
          :current-page='currentPage'
          :page-size='pageSize'
          :total='total'
          background
          layout='total, prev, pager, next,jumper'
          @current-change="$emit('onChangePage', $event)"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {tableMethods, tableProps} from "./index";

export default {
  name: "t-TableTemplate",
  props:tableProps,
  methods:{

    // 从按钮配置中过滤有权限的按钮
    filterPermissionBtn(buttons = []){
      if (!this.permission)return buttons;
      if (buttons.length){
        let permission = this.$store.getters.buttonPermissions;
        return permission.filter(val=>{
          return (!this.$utils.pageHandleButton.has(val.type) && buttons.includes(val.type));
        });
      }else{
        return this.$store.getters.buttonPermissions.filter(val=>!this.$utils.pageHandleButton.has(val.type));
      }

    },
    // 表格操作按钮配置
    handleBtn(data,row){
      let buttons = [];
      // button 的配置需要是以下格式
      // {type: 按钮类型 , 和后端权限一致
      // text: 按钮文本}
      if(data.buttons){
        buttons = data.buttons;
        // 如果是buttons状态
        if (typeof data.buttons === "function"){
          buttons = data.buttons(row) || [];
        }

      }else if(data.render){
        buttons = data.render(row) || [];
        if (!Array.isArray(buttons))buttons = [{
          button:buttons
        }]
        else{
          buttons = buttons.map(item=>{
            return {
              button:item
            }
          })
        }
      }
      return this.filterPermissionBtn(buttons)
    },
    cellStyle(row) {
      // type:  index , selection , expand
      if (row.column.type === 'selection') {
        return {
          paddingLeft: '9rem'
        }
      } else return {textIndent: '12rem', fontSize: '14rem'}
    },
    ...tableMethods
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/_setting.scss";
.t-table{

  .t-handle {
    margin-right: 15rem;
    text-indent: 0;
  }

  .t-handle:last-child {
    margin-right: 0;
  }

  .t-pagination {
    margin-top: 40rem;
  }
}

::v-deep .el-table th.el-table__cell {
  background-color: #F6F9FF;
  font-size: 14px;
  padding-left: 12px;
  font-weight: bold;
  color: #333333;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $menu;
}
.text-center{
  text-align: center;
}
</style>
