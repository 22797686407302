<template>
  <div class="t-table-template-top" :style="flex?{display:'flex',justifyContent:'space-between'}:{}">
      <div v-if='btnList.length' style='display: inline-block;margin-bottom: 20rem;'>
        <el-button v-for="item in btnList" type="primary" size="small" :icon="item.icon" @click="onHandleButton(item)">{{item.button}}</el-button>
      </div>
    <div v-if="!$slots['title-left'] && !btnList.length"></div>
    <slot name='title-left'></slot>
      <!--			</el-button-group>-->
      <t-search-block v-if='searchConfig'
                      :table="table"
                      ref='searchBlock'
                      :searchLoading="loading"
                      :search-config='searchConfig'
                      :is-check-input-box="isCheckInputBox"
                      :buttons="sButtons"
                      :reset-no-request="resetNoRequest"
                      @onFlex="(e)=>flex=e"
                      @onChange="val=>$emit('onChange',val)"
                      @onReset="$emit('onReset')"
                      @onDownload="$emit('onDownload')"
                      @onSearch="res=>$emit('onSearch',JSON.parse(JSON.stringify(res)))">

      </t-search-block>
  </div>
</template>

<script>
import TSearchBlock from "../SearchBlock";
import {topProps} from "./index";

export default {
  name: "TableTemplateTop",
  inject: ['table'],
  props:topProps,
  computed:{
    btnList(){
      if (this.permission) {
        let permissionButtons = this.$store.getters.buttonPermissions;
        let buttons = this.$utils.pageHandleButton;
        return permissionButtons.filter(res => {
          if (res.type === "add") {
            res.icon = "el-icon-plus";
          }
          return buttons.has(res.type);
        })
      }else {
        let li = this.buttonList;
        console.log(li,"======");
        if (this.hasAdd){
          /*li.unshift({
            button: "添加",
            type: "add",
            icon: "el-icon-plus"
          })*/
          if (li.filter(item=>item.button === "新增").length === 0)li.push({
            button: "新增",
            type: "add",
            icon: "el-icon-plus"
          })

        }
        if (this.hasBack){
          if (li.filter(item=>item.button === "返回").length === 0)li.push({
            button: "返回",
            type: "back",
            icon: "el-icon-arrow-left"
          })
        }
        return this.buttonList;
      }
    },
    sButtons(){
      let sb = this.searchButtons;
      if (this.isDownload && !sb.includes('export')){
        sb.push("export");
      }
      return sb;
    }
  },
  data() {
    return {
      flex:false,
    }
  },
  methods:{
    onHandleButton(item){
      let name = item.button[0].toUpperCase() + item.button.slice(1);
      if (item.type === "back"){
        this.$router.back();
      }
      this.$emit('on'+name,item);
    }
  },
  components:{
    TSearchBlock,
  }
}
</script>

<style lang="scss" scoped>
.t-table-template-top{
  //display: flex;
  //justify-content: space-between;
  //flex-wrap: wrap;
}
</style>
